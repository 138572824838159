/**
 * Navigation assets.
 */
$('ul.metismenu').metisMenu();


/**
 * Handles form data for custom links.
 */
$('.submit-button').click(function() {
    this.closest('form').submit();
    $('.form-signin').get(0).submit();
});
$("input").keypress(function(e) {
    if (e.which == 13) {
        e.preventDefault();
        this.closest('form').submit();
    }
});

/**
 * Datepicker - Go to today.
 */
 $.datepicker._gotoToday = function(id) {
     var target = $(id);
     var inst = this._getInst(target[0]);
     if (this._get(inst, 'gotoCurrent') && inst.currentDay) {
             inst.selectedDay = inst.currentDay;
             inst.drawMonth = inst.selectedMonth = inst.currentMonth;
             inst.drawYear = inst.selectedYear = inst.currentYear;
     }
     else {
             var date = new Date();
             inst.selectedDay = date.getDate();
             inst.drawMonth = inst.selectedMonth = date.getMonth();
             inst.drawYear = inst.selectedYear = date.getFullYear();
             this._setDateDatepicker(target, date);
             this._selectDate(id, this._getDateDatepicker(target));
     }
     this._notifyChange(inst);
     this._adjustDate(target);
 }


























//Loads the correct sidebar on window load,
//collapses the sidebar on window resize.
$(function() {
    $(window).bind("load resize", function() {
        if ($(this).width() < 768) {
            $('div.sidebar-collapse').addClass('collapse')
        } else {
            $('div.sidebar-collapse').removeClass('collapse')
        }
    })
})

// Convert a button to a fileselect upon click for appearance:
// Used in: Profile Updates / Image File selection
$(document).on('change', '.btn-file :file', function() {
  var input = $(this),
    numFiles = input.get(0).files ? input.get(0).files.length : 1,
    label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
    input.trigger('fileselect', [numFiles, label]);
});

// Places a text name of the file selected for Store Images in profile:
// Used in: Profile Updates / Image File selection
$('.btn-file :file').on('fileselect', function(event, numFiles, label) {
    var input = $(this).parents('.col-store-image').find(':text'),
        log = numFiles > 1 ? numFiles + ' files selected' : label;

    if( input.length ) {
        input.val(log);
    } else {
        if( log ) alert(log);
    }
});

$(".perms").click(function() {
    if( $(this).is(':checked') )
        $(this).siblings('.perms_hidden').prop('disabled', true);
    else
        $(this).siblings('.perms_hidden').prop('disabled', false);

});

// Cell shading (red) for over-budget manager hours:
// Used in: Payroll Hours
if($('#payroll-table').length) {
    var max_hours = Number($("#hour-manager").val());
    var current_hours;
    $('.manage-my-hours').each(function() {
        current_hours = Number($(this).val());
        if(current_hours > max_hours)
        {
            $(this).css('background-color', '#c72212');
            $(this).css('color', '#eee');
            $(this).css('font-weight', 'bold');
        }
        else {
            $(this).css('background-color', '#003987');
            $(this).css('color', '#eee');
            $(this).css('font-weight', 'bold');
        }
    });
}

// Cell highlighting of content (eg Excel) on click:
// Used in: Payroll Hours
$(".edit-all").on("click", function () {
    $(this).select();
});

// Allowing the use of Up/Down/Left/Right arrow keys on
// cell groupings (text boxes):
// Uses: cell-navigation.js
// Used in: Payroll Hours
$('#payroll-table').enableCellNavigation();
$('#budget-table').enableCellNavigation();

// Remove month selection if anything aside from "All" stores
// is selected:
// Used in: Gift Certificate / Transaction Report
$("select#store").change(function() {
    if(this.value == 0)
        $("div#select-month").show();
    else
        $("div#select-month").hide();
});

// Removes pre-formatted dates in the Sales Daily Creation screen
// when a custom date has begun being entered:
// Used in Sales / Create
$('input[name=for_date_entered]').keyup(function() {
    if($(this).val() == '')
    {
        $('select#for_date').prop('disabled', false);
        $(this).parents('div.col-lg-12').siblings().show();
        $(this).parents('div.col-lg-12').removeClass('col-lg-12').addClass('col-lg-6');
    }
    else
    {
        $('select#for_date').prop('disabled', true);
        $(this).parents('div.col-lg-6').siblings().hide();
        $(this).parents('div.col-lg-6').removeClass('col-lg-6').addClass('col-lg-12');
    }
});

// Shows or hides a password when toggled on Store Profile screen:
// Used in: Store Profiles / Password Toggle
$('.show-password').click(function(e) {
    e.preventDefault();
    if($(this).text() !== 'Show Password')
    {
        $(this).text('Show Password');
    }
    else
    {
        if( $(this).attr('id') )
            $(this).text( $(this).attr('id') );
        else
            $(this).text( 'Unlisted' );
    }

    $(this).toggleClass('label-danger').toggleClass('label-success');
});

// Bootstrap modal for Gift Certificates. Shows preview and print
// dialog on click:
// Used in: Gift Certificates / Re Print
$("#giftCards").on("click", ".fa-print", function(e){
    e.preventDefault();
    var id = $(this).attr('id');
    var frameSrc = "/gift-transactions/" + id + "/reprint";
    $('#gcModal').modal({
        'show': true,
        'keyboard': false
    })
    $('#gcModal').on('shown.bs.modal', function () {
        $('iframe').attr("src",frameSrc);
    });
});

// Bootstrap modal for Gift Certificates. Shows preview and print
// dialog on click:
// Used in: Gift Certificates / Show / Re-print
$("#reprint>a").click( function(e){
    e.preventDefault();
    var id = $(this).attr('id');
    var frameSrc = base + "/gift-transactions/" + id + "/reprint";
    $('#gcModal').modal({
        'show': true,
        'keyboard': false
    })
    $('#gcModal').on('shown.bs.modal', function () {
        $('iframe').attr("src",frameSrc);
    });
});

// Global hook, on a table where Font Awesome fa-times
// is also present, hook in and attempt to update
// the Delete URL:
// Used in: Tables / Delete
$(".table").on("click", ".fa-times", function(e) {
    e.preventDefault();

    $(".delete-url").attr("href", "");

    if(typeof optional === 'undefined')
        $(".delete-url").attr("href", "/" + table + "/" + $(this).parent("a").attr("id") + "/delete");
    else
        $(".delete-url").attr("href", "/" + table + "/" + optional + $(this).parent("a").attr("id") + "/delete");

});

$(".table").on("click", ".fa-square-o", function(e) {
    e.preventDefault();

    $(".delete-url").attr("href", "");

    if(typeof optional === 'undefined')
        $(".delete-url").attr("href", base + "/" + table + "/" + $(this).parent("a").attr("id") + "/mark");
    else
        $(".delete-url").attr("href", base + "/" + table + "/" + optional + $(this).parent("a").attr("id") + "/mark");

});


// Same as above, except used to hook into the Undo icon
// specifically on the transaction table and update the
// reverse url:
// Used in: Transactions / Undo Transaction
$("#transactions").on("click", ".fa-undo", function(e) {
    e.preventDefault();
    $(".delete-url").attr("href", "");
    $(".delete-url").attr("href", base + "/" + table + "/" + $(this).parent("a").attr("id") + "/reverse");
});

// Updates the Information Text when a different vendor is
// selected. This allows you to grab vendor information
// from the database about a specific type of data needed:
// Used in: Packing Slips / Create
$("select[name='vendors_id']").change(function(){
    $.ajax({
        type: 'POST',
        url: '/vendors/info',
        data: {
            id: $(this).val()
        },
        success: function(response) {
            $("input[name='information']").attr('placeholder', response);
        }
    });
});

// Sales / DataTables Initialization
$('#sales-table').dataTable({
    "bStateSave": false,
    "bProcessing": false,
    "bServerSide": true,
    "bDestroy":  true,
    "iDisplayLength": 10,
    "sAjaxSource": "/sales/data",
    "sPaginationType": "full_numbers",
    "aaSorting": [ [1, 'asc'], [2,'desc'] ]
});

// Sales Archive / DataTables Initialization
$('#sales-archive-table').dataTable({
    "bStateSave": true,
    "bProcessing": false,
    "bServerSide": true,
    "bDestroy":  true,
    "iDisplayLength": 10,
    "sAjaxSource": "/sales/data-archive",
    "sPaginationType": "full_numbers",
    "aaSorting": [ [1, 'asc'], [2,'desc'] ]
});

// Vendors / DataTables Initialization
$('#vendors-table').dataTable({
    "bProcessing": false,
    "bServerSide": true,
    "bDestroy":  true,
    "iDisplayLength": 10,
    "sAjaxSource": "vendors/data",
    "sPaginationType": "full_numbers",
    "aaSorting": [ [1,'desc'] ]
});

// Stores / DataTables Initialization
$('#stores-table').dataTable({
    "bProcessing": false,
    "bServerSide": true,
    "bDestroy":  true,
    "iDisplayLength": 10,
    "sAjaxSource": "stores/data",
    "sPaginationType": "full_numbers",
    "aaSorting": [ [1,'desc'] ]
});

// Entity / DataTables Initialization
$('#entity-table').dataTable({
    "bProcessing": false,
    "bServerSide": true,
    "bDestroy":  true,
    "iDisplayLength": 10,
    "sAjaxSource": "entity/data",
    "sPaginationType": "full_numbers",
    "aaSorting": [ [1,'desc'] ]
});

// Custom checkmarks using Font Awesome, AJAX, and
// clever toggles:
// Used in: Packing Slips / Index
$('.table').on("click", ".slip-checks", function(e) {
    e.preventDefault();
    marks = $(this).children('i');
    if( marks.hasClass('fa-square') )
    {
        $.ajax({
            type: 'GET',
            url: '/packing-slips/receive',
            data: {
                id: $(this).attr("id"),
                task: 1
            },
            success: function(response) {
                marks.removeClass('fa-square');
                marks.addClass('fa-check-square');
                $("input[type='search']").select();
            }
        });
    }
    else
    {
        $.ajax({
            type: 'GET',
            url: '/packing-slips/receive',
            data: {
                id: $(this).attr("id"),
                task: 0
            },
            success: function(response) {
                marks.removeClass('fa-check-square');
                marks.addClass('fa-square');
            }
        });
    }
});


// Custom checkmarks using Font Awesome, AJAX, and
// clever toggles:
// Used in: Packing Slips / Index
$('.table').on("click", ".verified", function(e) {
    e.preventDefault();
    marks = $(this).children('i');
    if( marks.hasClass('fa-square') )
    {
        $.ajax({
            type: 'GET',
            url: '/verify/quick',
            data: {
                id: $(this).attr("id"),
                task: 1
            },
            success: function(response) {
                marks.removeClass('fa-square');
                marks.addClass('fa-check-square');
                $("input[type='search']").select();
            }
        });
    }
    else
    {
        $.ajax({
            type: 'GET',
            url: '/verify/quick',
            data: {
                id: $(this).attr("id"),
                task: 0
            },
            success: function(response) {
                marks.removeClass('fa-check-square');
                marks.addClass('fa-square');
            }
        });
    }
});